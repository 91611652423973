.loader {
  display: flex;
  width: 90px;
  height: 90px;
  align-items: center;
  justify-content: center;
}

.loader::after {
  content: "";
  width: 100%;
  height: 100%;
  border: 4px solid #535353;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: loading 1s ease infinite;
  transition: border-color 0.5s ease;
}

@keyframes loading {
  0% {
    transform: rotate(0turn);
  }
  100% {
    transform: rotate(1turn);
  }
}
