.loader {
  position: relative;
  display: flex;
  width: 315px;
  height: 315px;
  align-items: center;
  justify-content: center;
}

.loader::after {
  content: "";
  width: 100%;
  height: 100%;
  border: 2px solid #303030;
  border-top-color: #99ff48;
  border-radius: 50%;
  animation: loading 1s ease infinite;
  transition: border-color 0.5s ease;
}

.cancel_loader::after {
  border: 2px solid #303030;
  /* animation: none; */
}

@keyframes loading {
  0% {
    transform: rotate(0turn);
  }
  100% {
    transform: rotate(1turn);
  }
}
