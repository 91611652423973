@keyframes flyUpFadeOut {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-50px);
    opacity: 0;
  }
}

.fly_up_fade_out {
  animation: flyUpFadeOut 1s ease-out forwards;
}
