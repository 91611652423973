@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url('https://fonts.cdnfonts.com/css/helvetica-neue-55');
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  overflow: hidden;
}

body {
  margin: 0;
  width: 100vw;
  height: 100svh;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  font-family: "Inter", sans-serif;
  color: #ffffff;
  background-color: #191716;
}

.center-root {
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-vertical-root {
  display: flex;
  align-items: center;
}

.space-between-root {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.eclipse {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* default 1 line */
  -webkit-box-orient: vertical;
}

.h-fullScreen {
  height: calc(100vh - 7rem);
}

.min-h-fullScreen {
  min-height: calc(100vh - 7rem);
}

::-webkit-scrollbar {
  width: 2px;
  height: 4px;
  display: flex;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  /* Overwrite react-camera-pro */
}

/* .jLZrnX {
  position: unset !important;
  height: 100%;
  * {
    position: unset !important;
  }
} */