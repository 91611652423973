.scroll_parent {
  display: flex;
  align-items: center;
  justify-items: center;
  overflow-x: auto;
  white-space: nowrap;
  padding: 0.5rem 0;
}

.scroll_parent > * {
  flex: 0 0 auto;
}
