.fade-in {
    animation: slideIn 0.3s ease forwards
}

.fade-out {
    animation: slideOut 0.3s ease forwards
}

.menu-post {
    transform: translateX(100%)
}

@keyframes slideIn {
    from {
        transform: translateX(100%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideOut {
    from {
        transform: translateX(0);
        opacity: 1;
    }

    to {
        transform: translateX(100%);
        opacity: 0;
    }
}